
const GetBlogs = `
  query Query {
    Articles {
        items {
          title
          excerpt
          cover {
            url
          }
          _slug
        }
      }
  }
`

export default GetBlogs
