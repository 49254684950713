<script>
  // No changes in script
</script>

<style>
  .page-container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .heading-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
  }

  h1 {
    font-size: 3.5rem;
    letter-spacing: 2px;
  }

  h2 {
    font-size: 2rem;
    letter-spacing: 1.5px;
  }

  h1, h2 {
    text-align: center;
  }

  button {
    background-color: #9bff8e;
    color: #000;
    border: none;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 8px 8px 0px #000;
    margin-top: 40px;
    font-size: 1.5rem;
    cursor: pointer;
  }

  button:hover {
    box-shadow: 8px 8px 5px #000;
  }

  button:active {
    background-color: #9bff8e;
    color: #000;
    box-shadow: inset 8px 8px 5px #000;
  }

  .background {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: -1;
  }

  .character1-wrapper {
    display: flex;
    align-items: start;
    margin-top: 100px;
  }

  .character2-wrapper {
    display: flex;
    align-items: end;
    margin-bottom: 150px;
  }

  .character1 {
    width: 190px;
  }

  .character2 {
    width: 280px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  /* Media query for tablets and below */
  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .heading-container {
      margin-top: 0;
    }

    .heading-container {
    height: auto;
  }

    .character1-wrapper, .character2-wrapper {
      margin: 20px 0;
    }

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    button {
      font-size: 1.2rem;
      padding: 10px;
    }

    .character1 {
      width: 150px;
    }

    .character2 {
      display: none;
    }
  }

  /* Media query for mobile phones */
  @media (max-width: 480px) {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.2rem;
    }

    button {
      font-size: 1rem;
      padding: 8px;
    }

    .character2-wrapper {
      visibility: hidden;
      margin: 0;
    }
  }
</style>

<div class="page-container">
  <div class="character1-wrapper">
    <img class="character1" src="./static/character1.svg" alt="illustration of woman with paper">
  </div>
  <div class="heading-container">
    <h1>Smart English</h1>
    <h2>Find the Best English Courses</h2>
    <a href="#/courses"><button>View Courses</button></a>
  </div>
  <div class="character2-wrapper">
    <img class="character2" src="./static/character2.svg" alt="illustration of man on a computer">
  </div>
  <img class="background" src="./static/layered-waves.svg" alt="background" />
</div>