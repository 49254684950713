<script>
    import { params } from 'svelte-spa-router';
    import prepr from '../lib/prepr'
    import getBlog from '../lib/queries/getBlog' 
    import DOMPurify from 'dompurify';
    
    function sanitizeHTML(html) {
    return DOMPurify.sanitize(html);
  }

    let getApiResponse = () => {}
    let post = {
        content: [{}],
        cover: [{}],
    }

    params.subscribe((param) => {
        if (param) {
            const slug = param.id
            getApiResponse = async () => {
                const response = await prepr(getBlog, {slug})
                const { data } = await response.json()
                const article = data.Article 
                return article
            }
            getApiResponse().then(postFromApi => post = postFromApi)
        }
    })

</script>
  
<style>
  ._blogpost-unique-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: wrap;
      width: 80%;
      max-width: 1000px;
      margin: auto;
  }

  h1 {
      text-align: center;
      margin-top: 80px;
      font-size: 3rem;
  }

  img {
      display: block;
      width: 800px;
      margin: 50px auto 0 auto;
  }


  /* hack for styling dynamically added content - specificity should prevent styles being applied globally */
  :global(._blogpost-unique-wrapper p) {
        font-size: 1.5rem;
    }

    :global(._blogpost-unique-wrapper li) {
        font-size: 1.5rem;
    }

    :global(._blogpost-unique-wrapper h2) {
        font-size: 2rem;
    }

    :global(._blogpost-unique-wrapper h3) {
        font-size: 1.5rem;
    }
</style>

<img src={post.cover[0].url} alt={post.title}>
<h1>{post.title}</h1>
<div class="_blogpost-unique-wrapper">
    {#each post.content as content}
        {#if content.__typename}
            {#if content.items}
                <img src={content.items[0].url} />
            {/if}
        {/if}
        {@html sanitizeHTML(content.body)}
    {/each}
</div>