const endpoint = PREPR_ENDPOINT

const prepr = async (query, variables) => {
    const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query, variables }),
    })
    return response
}

export default prepr
