
const post =`
query($slug: String){
	Article (slug: $slug) { 
        _id
		title
		_slug
		categories { 
			__typename
		}
		cover { 
			_id
			url
		}
		content { 
			__typename
    ... on Text {
      body
      format
    }
	... on Assets {
		items {
		  url
		}
	  }
  }
		seo { 
			_id
			title
			description
			social_media_image { 
				_id
				url
			}
		}
	}
}
`

export default post