<script>
    export let title;
    export let imgUrl;
    export let link;
</script>

<style>
    .card {
        width: 80vw;
        max-width: 1000px;
        background-color: #13505b;
        color: #d7d9ce;
        padding: 30px;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        box-shadow: 10px 10px 0px #000;
    }

    img {
        width: 90%;
    }

    h3 {
        font-size: 2rem;
        text-align: center;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    /* Media query for tablets and below */
    @media (max-width: 768px) {
        .card {
            padding: 20px;
            margin-bottom: 30px;
        }

        img {
            width: 85%; /* Adjust image width */
        }

        h3 {
            font-size: 1.8rem; /* Slightly reduce font size */
        }
    }

    /* Media query for mobile phones */
    @media (max-width: 480px) {
        img {
            width: 80%; /* Adjust image width */
        }

        h3 {
            font-size: 1.6rem; /* Further reduce font size */
        }
    }
</style>

<a href={link}>
    <div class="card">
        <img src={imgUrl} alt={"Image for " + title} />
        <h3>{title}</h3>
    </div>
</a>