
<script>
  // Add your blog page logic here
</script>

<style>

h1 {
  text-align: center;
  font-size: 3rem;
  margin-top: 80px;
}

h2 {
  font-size: 1.8rem;
}

  .page-wrapper {
    display: flex;
    justify-content: center;
  }

  .about-wrapper {
    width: 800px;
    text-align: center;
    font-size: 1.5rem;
  }

  img {
    width: 400px
  }

  a {
    color: inherit;
  }

  /* Media query for tablets and below */
@media (max-width: 768px) {
    h1 {
        font-size: 2.5rem;
        margin-top: 40px;
    }

    h2 {
        font-size: 1.5rem;
    }

    .page-wrapper {
        flex-direction: column;  /* Stack children vertically */
        align-items: center;
    }

    .about-wrapper {
        width: 100%;   /* Use full width for better readability */
        padding: 0 20px; /* Give some side padding */
        font-size: 1.2rem;
    }

    img {
        width: 300px;  /* Adjust the image width for tablets */
        margin-top: 20px; /* Add some spacing between text and image */
    }
}

/* Media query for mobile phones */
@media (max-width: 480px) {
    h1 {
        font-size: 2rem;
    }

    .about-wrapper {
        font-size: 1rem;
    }

    img {
        width: 250px;  /* Adjust the image width for mobiles */
    }
}
</style>
  
<h1>About</h1>
<div class="page-wrapper">
  <div class="about-wrapper">
    <p>
      Learning English is hard but finding a course shouldn’t be. Our goal at Smart English is to help you find a course which is the best fit for you. We trawl through hundreds of websites so that you don't have to. We want to see the English language accessible to as many people as possible, and Smart English aims to provide the tools to make that as quick cheap and accessible as possible.
    </p>
    <h2>Course Providers</h2>
    <p>
      We know there’s lots of dedicated teachers of English out there who provide high quality courses. We want to help you find an audience. Get in touch and we will add you to our database so that your course can be found by the Smart English community. <a href="mailto:smartenglish@mail.com">smartenglish@mail.com</a>
    </p>
    <h2>Something Wrong?</h2>
    <p>
      Our team maintain a database of all the external courses listed on our website. Sometimes we get the information wrong, and sometimes it goes out of date before we can correct it. If you spot something that isn't quite right we would love it if you could email us and let us know
    </p>
    <p>
      If you've got any other feedback, good or bad we'd love to hear from you.
    </p>
    <p>
     <a href="mailto:smartenglish@mail.com">smartenglish@mail.com</a>
    </p>
  </div>
  <div class="img-wrapper">
    <img src="./static/character3.svg" alt="illustration of man holding paper">
  </div>
</div>
  