<script>
    import { onMount, onDestroy } from 'svelte';
    import { slide } from 'svelte/transition';
    let isOpen = false;

    function toggleMenu() {
      isOpen = !isOpen;
    }

    function closeMenu(event) {
        if (isOpen && event.target !== document.querySelector('.burger-menu')) {
            isOpen = false;
        }
    }

    onMount(() => {
        document.body.addEventListener('click', closeMenu);
        return () => {
            document.body.removeEventListener('click', closeMenu);
        }
    });
</script>
  
  <style>
  header {
      position: sticky;
      top: 0;
      background-color: #13505B;
      color: #D7D9CE;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #D7D9CE;
  }
  
  nav {
      display: flex;
      gap: 20px;
      margin-right: 60px;
  }
  
  h1 {
      margin: 0 0 0 60px;
      padding: 0;
  }
  
  h1 a {
      letter-spacing: 3px;
      font-size: 2.5rem;
  }
  
  a {
      color: inherit;
      text-decoration: none;
      font-size: 1.4rem;
  }
  
  .burger-menu {
      display: none;
      cursor: pointer;
      font-size: 2rem;
  }
  
  /* Media query for tablet and below */
  @media (max-width: 768px) {
      nav {
          display: none;
      }
  
      .burger-menu {
          display: block;
      }
  
      nav[aria-expanded="true"] {
        display: flex;
        flex-direction: column; 
        gap: 30px;
        position: absolute;
        right: 0;
        top: 0; 
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: rgba(19, 80, 91, 0.95);
        border: solid 1px #D7D9CE;
        padding: 10px;
        border-radius: 5px;
    }
    a {
        font-size: 2rem;
        margin: 10px 0;
    }
  }
  </style>
  
  <header>
    <h1><a href="/">Smart English</a></h1>
    <button class="burger-menu" on:click={toggleMenu}>&#9776;</button>
    <nav aria-expanded={isOpen} transition:slide={{duration: 300}}>
        <a href="/">Home</a>
        <a href="#/courses">Courses</a>
        <a href="#/about">About</a>
        <a href="#/blog">Blog</a>
    </nav>
</header>