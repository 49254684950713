<script>
  import BlogDisplay from '../components/BlogDisplay.svelte';
  import prepr from '../lib/prepr'
  import getBlogs from "../lib/queries/getBlogs"

  const getApiResponse = async () => {
    const response = await prepr(getBlogs)
    const { data } = await response.json()
    const blogList = data.Articles.items 
    return blogList
  }

  let posts = []
  getApiResponse().then(postsFromApi => {
    posts = postsFromApi
    })
</script>
  
<style>
  h1 {
    text-align: center;
    font-size: 3rem;
    margin-top: 80px;
  }

  .posts-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
  }

    /* Media query for tablets and below */
    @media (max-width: 768px) {
    h1 {
        font-size: 2.5rem;
        margin-top: 40px;
    }

    .posts-wrapper {
        gap: 20px; /* Adjust the gap between individual posts */
    }
  }

  /* Media query for mobile phones */
  @media (max-width: 480px) {
    h1 {
        font-size: 2rem;
        margin-top: 20px;
    }
  }
</style>
  
<h1>Smart English Blog</h1>
<div class="posts-wrapper">
  {#each posts as post}
  <BlogDisplay title={post.title} link={`#/blog/${post._slug}`} imgUrl={post.cover[0].url} />
  {/each}
</div>